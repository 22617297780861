import React, { Component } from "react";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

class SelectboxQuestion extends Component {
  handleChange = (e, answer) => {
    this.props.onChangeAnswer(answer);
  };

  render() {
    const { question, options, answer } = this.props;
    return (
      <div className="d-block choice-question-container">
        <div className="mb-3">
          <h3>{question}</h3>
        </div>
        <div className="d-block autocomplete">
          <Autocomplete
            className="form-control"
            disablePortal
            onChange={this.handleChange}
            value={answer}
            options={options}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </div>
    );
  }
}

export default SelectboxQuestion;
