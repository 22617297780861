import { createAsyncThunk } from "@reduxjs/toolkit";
import { preLoginService } from "Services";

export const signUp = createAsyncThunk("auth/signUp", async (params) => {
  try {
    const url = `/respondent/register`;
    const response = await preLoginService.post(url, params);
    return response;
  } catch (error) {
    throw error.response?.data;
  }
});

export const login = createAsyncThunk("auth/login", async (data) => {
  const url = `/respondent/login`;
  try {
    const response = await preLoginService.post(url, data);
    return response;
  } catch (error) {
    throw error.response?.data;
  }
});

export const saveFCMTokenReq = createAsyncThunk(
  "respondent/saveFcmToken",
  async (fcmToken) => {
    const url = `/respondent/saveFCMToken`;
    try {
      const response = await preLoginService.post(url, { fcmToken });
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);
