import React, { Component } from "react";
import FormHelperText from "@mui/material/FormHelperText";

export class CustomHeadonicScale extends Component {
  handleStateChange = (value) => {
    this.props.handleStateChange(value);
  };

  renderScale = () => {
    const retData = [];
    let { ans = "", styleDetails = {}, opts, isDisabled } = this.props;
    for (let i = 0; i < opts.length; i++) {
      retData.push(
        <li
          style={{
            background: ans === opts[i] ? "#434bdf" : "",
            width: "auto",
            paddingLeft: "10px",
            paddingRight: "10px",
            color: ans === opts[i] ? "#ffffff" : styleDetails.optionColor,
          }}
          onClick={(e) =>
            !isDisabled ? this.handleStateChange(opts[i]) : null
          }
        >
          {opts[i]}
        </li>
      );
    }
    return retData;
  };

  render() {
    let {
      errorMsg,
      details,
      description,
      styleDetails = {},
      question,
    } = this.props;
    return (
      <div className="d-block choice-question-container">
        <div className="mb-3">
          <h3
            style={{
              color: styleDetails.questionColor,
              fontSize: `${styleDetails.questionFontSize}px`,
              fontFamily: styleDetails.questionFontFamily,
            }}
          >
            {question} {details?.isMandatory ? "*" : ""}
          </h3>
          <span
            style={{
              color: styleDetails.descriptionColor,
              fontSize: `${styleDetails.descriptionFontSize}px`,
              fontFamily: styleDetails.descriptionFontFamily,
            }}
          >
            {description}
          </span>
        </div>

        <div className="d-block hedonic-container">
          <ul className="d-flex flex-wrap">{this.renderScale()}</ul>
          {errorMsg ? (
            <FormHelperText
              className="MuiFormHelperText-root Mui-error"
              style={{
                color: styleDetails.errorColor,
                fontSize: styleDetails.errorFontSize,
                fontFamily: styleDetails.errorFontFamily,
              }}
            >
              {errorMsg}
            </FormHelperText>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}
