import React, { Component } from "react";

export default class ThankYou extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { description, styleDetails = {}, image, header } = this.props.data;

    return (
      <div className="input-choice-container">
        <div
          className="d-flex p-3 rounded justify-content-center w-100 align-items-center upload-library  mb-4"
          style={{ maxHeight: "300px", border: "none" }}
        >
          <img
            src={image}
            alt="Thank you image"
            style={{ maxWidth: "100%", maxHeight: "260px" }}
          />
        </div>
        <h2
          className="d-block mb-3"
          style={{ fonntSize: styleDetails.surveyHeaderFontSize }}
        >
          {header || "Title -"}
        </h2>
        <div
           className="description-box p-0 mb-4"
           style={{
             background: "transparent",
             boxShadow: "none",
             border: "none",
           }}
        >
          <p
            style={{
              color: styleDetails.descriptionColor,
              fontSize: `${`${styleDetails.descriptionFontSize}px`}px`,
              fontFamily: styleDetails.descriptionFontFamily,
            }}
          >
            {description || "Description -"}
          </p>
        </div>
      </div>
    );
  }
}
const translatedThankYou = ThankYou;

export { translatedThankYou as ThankYou };
