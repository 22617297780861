import { createSlice } from "@reduxjs/toolkit";
import {
  getUserProfile,
  updateUserProfile,
  deleteUserAccount,
} from "./ProfileThunk";
import { status } from "Constants";

const ProfileSlice = createSlice({
  name: "profile",
  initialState: {
    userProfileData: {
      status: null,
      data: {},
    },
    updatedProfileData: {
      status: null,
      data: {},
    },
    deletedUserAccountData: {
      status: null,
      data: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending.toString(), (state, action) => {
        return {
          ...state,
          userProfileData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(getUserProfile.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          userProfileData: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(getUserProfile.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          userProfileData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(updateUserProfile.pending.toString(), (state, action) => {
        return {
          ...state,
          updatedProfileData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(updateUserProfile.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          updatedProfileData: {
            status: status.SUCCESS,
            data: payload,
          },
        };
      })
      .addCase(updateUserProfile.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          updatedProfileData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(deleteUserAccount.pending.toString(), (state, action) => {
        return {
          ...state,
          deletedUserAccountData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(deleteUserAccount.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          deletedUserAccountData: {
            status: status.SUCCESS,
            data: payload,
          },
        };
      })
      .addCase(deleteUserAccount.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          deletedUserAccountData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      });
  },
});

export default ProfileSlice.reducer;
