import React, { Component } from "react";
import { getAllSurvey } from "ReduxStore/Survey/SurveyThunk";
import { PAGE_NAME, status } from "Constants";
import { navigateRouter } from "Utils/navigateRouter";
import { connect } from "react-redux";
import { Loading } from "Postlogin/Components/Loading";
import Header from "Postlogin/Components/Header";
import { Box } from "@mui/material";

class ServerAvailable extends Component {
  constructor() {
    super();
    this.state = {
      surveys: [],
    };
  }

  componentDidMount() {
    this.props.getAllSurvey();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.allSurveyData.status !== prevProps.allSurveyData.status) {
      if (this.props.allSurveyData.status === status.SUCCESS) {
        let surveys = this.props.allSurveyData.data || [];
        this.setState({ surveys });
      }
    }
  };

  render() {
    let { surveys } = this.state;
    let { allSurveyData } = this.props;
    return (
      <Box className="main-container">
        <Header
          data={{
            header: "Available Surveys",
            page: PAGE_NAME.SURVEY_AVAILABLE,
            backUrl: `/app/postlogin/${PAGE_NAME.DASHBOARD}`,
          }}
        />
        <div className="serverAvailable-container">
          {allSurveyData.status === status.IN_PROGRESS ? (
            <div
              className="text-center d-flex align-items-center justify-content-center"
              style={{ height: "83vh" }}
            >
              <Loading />
            </div>
          ) : surveys.length ? (
            surveys.map((survey, index) => {
              return (
                <div
                  className="survey-box"
                  key={index}
                  onClick={() => {
                    this.props.navigate(
                      `/app/postlogin/survey/${survey.surveyId._id}`
                    );
                  }}
                >
                  <p className="survey-name">{survey.surveyId.surveyName}</p>
                  <p className="survey-description">
                    {survey.surveyId.surveyDescription}
                  </p>
                </div>
              );
            })
          ) : (
            <div
              className="text-center d-flex align-items-center justify-content-center"
              style={{ height: "83vh" }}
            >
              <h4 className="description">
                {allSurveyData.status === status.FAILURE
                  ? allSurveyData?.data === "Rejected"
                    ? "There is some internal server error."
                    : allSurveyData?.data
                  : "No survey found."}
              </h4>
            </div>
          )}
        </div>
      </Box>
    );
  }
}

function mapStateToProps(state) {
  const { allSurveyData } = state.survey;
  return {
    allSurveyData,
  };
}

const mapDispatchToProps = {
  getAllSurvey,
};

const connectedServerAvailable = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServerAvailable);
export default navigateRouter(connectedServerAvailable);
