import { createAsyncThunk } from "@reduxjs/toolkit";
import { postLoginService } from "Services";

export const getAllSurvey = createAsyncThunk(
  "dashboard/getAllSurvey",
  async (params) => {
    try {
      const url = `/respondent/survey`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getSurveyById = createAsyncThunk(
  "dashboard/getSurveyById",
  async (surveyId) => {
    try {
      const url = `/respondent/survey/${surveyId}`;
      const response = await postLoginService.post(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getDashboardDetails = createAsyncThunk(
  "dashboard/getDashboardDetails",
  async (respondentId) => {
    try {
      const url = `/respondent/dashboard/getDashboardDetails`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);
