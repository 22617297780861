import React, { Component } from "react";
import {
  getOnboardingQuestions,
  saveOnboardingAnswer,
} from "ReduxStore/Onboarding/OnboardingThunk";
import { connect } from "react-redux";
import { SURVEY_CONSTANT, PAGE_NAME, status } from "Constants";
import { navigateRouter } from "Utils/navigateRouter";
import InputQuestion from "./Questions/Input";
import RadioQuestions from "./Questions/Radio";
import CheckboxQuestions from "./Questions/Checkbox";
import SelectboxQuestion from "./Questions/Selectbox";
import MultiSelectQuestion from "./Questions/MultiSelect";
import DateQuestion from "./Questions/Date";
import { LoadingButton } from "@mui/lab";
import Header from "Postlogin/Components/Header";
import { Box } from "@mui/material";
import { Loading } from "Postlogin/Components/Loading";
import { GA } from "Utils/ga";
import { GOOGLE_ANALYTICS, LOCALSTORAGE_ITEMS } from "Constants";
import { LocalStorageService } from "Utils/HelperFunctions";
import { Sort } from "@mui/icons-material";

// For parent question functionality

//   const dummyResponse = {
//     "status": true,
//     "message": "success",
//     "data": {
//         "question": "Which state are you from?",
//         "options": [
//             "Gujarat",
//             "Maharastra",
//             "Rajasthan"
//         ],
//         "questionType": "SELECT",
//         "key": "state",
//         "category": "state",
//         "active": true,
//         "answerType": "STRING",
//         "isParentQuestion": true,
//         "childQuestions": [{
//             "answer": "Gujarat",
//             "question": {
//                 "question": "Which state are you from?",
//                 "options": [
//                     "Gujarat",
//                     "Maharastra",
//                     "Rajasthan"
//                 ],
//                 "questionType": "SELECT",
//                 "key": "state",
//                 "category": "state",
//                 "active": true,
//                 "answerType": "STRING",
//                 "isParentQuestion": true,
//                 "childQuestions": [{
//                     "answer": "Gujarat",
//                     "question":
//                 }]
//             }
//         },{
//             "answer": "Maharastra",
//             "question": {
//                 "question": "Which city are you from?",
//                 "options": [
//                     "Gujarat",
//                     "Maharastra",
//                     "Rajasthan"
//                 ],
//                 "questionType": "INPUT",
//                 "key": "state",
//                 "category": "state",
//                 "active": true,
//                 "answerType": "STRING"
//             }
//         }]
//     }
// }
class OnBoarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onboardingQuestions: [],
      currentQuestion: 0,
      isAnswerValid: false,
      answer: null,
      childAnswer: null,
    };
  }

  componentDidMount() {
    this.props.getOnboardingQuestions();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.allOnBoardingQuestions.status !==
      prevProps.allOnBoardingQuestions.status
    ) {
      if (this.props.allOnBoardingQuestions.status === status.SUCCESS) {
        let onboardingQuestions =
          Array.isArray(this.props.allOnBoardingQuestions.data) &&
          this.props.allOnBoardingQuestions.data?.length > 0
            ? JSON.parse(
                JSON.stringify(this.props.allOnBoardingQuestions.data)
              )?.sort((a, b) => {
                return a?.order && b?.order ? a?.order - b?.order : 0;
              })
            : [];
        this.setState({ onboardingQuestions });
      }
    }
    if (
      this.props.onboardingAnsData.status !== prevProps.onboardingAnsData.status
    ) {
      if (this.props.onboardingAnsData.status === status.SUCCESS) {
        const userData =
          JSON.parse(
            LocalStorageService.getData(LOCALSTORAGE_ITEMS.userData)
          ) || {};
        GA.dispatchGAEvent(
          GOOGLE_ANALYTICS.EVENT_CATEGORIES.RESPONDENT,
          GOOGLE_ANALYTICS.EVENT_ACTIONS.ONBOARDING_ANSWER_SUBMIT,
          `id=${userData._id}`
        );
        const { currentQuestion, onboardingQuestions, answer } = this.state;
        if (currentQuestion + 1 < onboardingQuestions.length) {
          this.setState({
            currentQuestion: currentQuestion + 1,
            answer: null,
          });
        } else {
          if (onboardingQuestions.length === 0) {
            console.log("There are no onboarding question remaining.");
          } else {
            this.props.navigate("/app/postlogin/dashboard");
          }
        }
      }
    }
  };

  renderQuestion = () => {
    const { onboardingQuestions, currentQuestion, answer } = this.state;
    const { allOnBoardingQuestions } = this.props;
    if (allOnBoardingQuestions.status === status.IN_PROGRESS) {
      return (
        <div
          className="text-center d-flex align-items-center justify-content-center"
          style={{ height: "83vh" }}
        >
          <Loading />
        </div>
      );
    }
    if (!onboardingQuestions || onboardingQuestions.length === 0) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "75vh" }}
        >
          <h4 className="text-center">
            {allOnBoardingQuestions.status === status.FAILURE
              ? allOnBoardingQuestions?.data === "Rejected"
                ? "There is some internal server error."
                : allOnBoardingQuestions?.data
              : "There are no onboarding question remaining."}
          </h4>
        </div>
      );
    }
    if (
      onboardingQuestions &&
      onboardingQuestions.length > 0 &&
      onboardingQuestions[currentQuestion]
    ) {
      const { question, questionType, options } =
        onboardingQuestions[currentQuestion];
      if (questionType === SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.INPUT_BOX) {
        return (
          <InputQuestion
            question={question}
            onChangeAnswer={this.handleOnChange}
            answer={answer ? answer : ""}
          />
        );
      } else if (
        questionType === SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.SINGLE_CHOICE
      ) {
        return (
          <RadioQuestions
            options={options}
            question={question}
            onChangeAnswer={this.handleOnChange}
            answer={answer ? answer : ""}
          />
        );
      } else if (
        questionType ===
        SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.MULTIPLE_CHOICE
      ) {
        return (
          <CheckboxQuestions
            options={options}
            question={question}
            onChangeAnswer={this.handleOnChange}
            answer={answer && answer.length > 0 ? answer : []}
          />
        );
      } else if (
        questionType === SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.SELECT
      ) {
        return (
          <SelectboxQuestion
            options={options}
            question={question}
            onChangeAnswer={this.handleOnChange}
            answer={answer ? answer : ""}
          />
        );
      } else if (
        questionType === SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.MULTI_SELECT
      ) {
        return (
          <MultiSelectQuestion
            options={options}
            question={question}
            onChangeAnswer={this.handleOnChange}
            answer={answer && answer.length > 0 ? answer : []}
          />
        );
      } else if (
        questionType === SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.DATE
      ) {
        return (
          <DateQuestion
            options={options}
            question={question}
            onChangeAnswer={this.handleOnChange}
            answer={answer}
          />
        );
      }
    }
  };

  handleValidation = () => {
    const { currentQuestion, onboardingQuestions, answer, childAnswer } =
      this.state;
    const retData = {
      isValid: false,
    };
    let quesOrChildQues = [];
    let currentChildQuestion;

    if (onboardingQuestions[currentQuestion]?.childQuestions?.length) {
      currentChildQuestion = onboardingQuestions[
        currentQuestion
      ]?.childQuestions.find(
        (childQuestion) => childQuestion?.answer === answer
      );
    }

    if (onboardingQuestions[currentQuestion]) {
      quesOrChildQues.push(onboardingQuestions[currentQuestion]);
      if (currentChildQuestion) {
        quesOrChildQues.push({
          ...currentChildQuestion.question,
          isChildQuestion: true,
        });
        retData["isChildValid"] = false;
      }
      quesOrChildQues.forEach((ques) => {
        const { questionType, isChildQuestion } = ques;

        if (
          questionType === SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.INPUT_BOX ||
          questionType === SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.SELECT
        ) {
          if (isChildQuestion) {
            if (currentChildQuestion && childAnswer && childAnswer.trim()) {
              retData.isChildValid = true;
            }
          } else {
            if (answer && answer.trim()) {
              retData.isValid = true;
            }
          }
        } else if (
          questionType ===
            SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.SINGLE_CHOICE ||
          questionType ===
            SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.MULTIPLE_CHOICE ||
          questionType === SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.MULTI_SELECT
        ) {
          if (isChildQuestion) {
            if (currentChildQuestion && childAnswer && childAnswer.length > 0) {
              retData.isChildValid = true;
            }
          } else {
            if (answer && answer.length > 0) {
              retData.isValid = true;
            }
          }
        } else if (
          questionType === SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.DATE
        ) {
          if (isChildQuestion) {
            if (
              currentChildQuestion &&
              childAnswer &&
              !isNaN(new Date(childAnswer))
            ) {
              retData.isChildValid = true;
            }
          } else {
            if (answer && !isNaN(new Date(answer))) {
              retData.isValid = true;
            }
          }
        }
      });
    }
    return {
      isValid: currentChildQuestion
        ? retData.isValid && retData.isChildValid
        : retData.isValid,
    };
  };

  handleOnChange = (answer, isChildAns = 0) => {
    if (isChildAns) {
      this.setState({
        childAnswer: answer,
      });
    } else {
      this.setState({
        answer,
        childAnswer: null,
      });
    }
  };

  onClickNext = () => {
    const { isValid } = this.handleValidation();
    if (isValid) {
      const { currentQuestion, onboardingQuestions, answer } = this.state;
      const sendData = {
        questionId: onboardingQuestions[currentQuestion]._id,
        answer,
      };
      this.props.saveOnboardingAnswer(sendData);
    }
  };

  renderChildQuestion = () => {
    const { onboardingQuestions, currentQuestion, childAnswer, answer } =
      this.state;

    if (
      onboardingQuestions[currentQuestion]?.isParentQuestion &&
      onboardingQuestions[currentQuestion]?.childQuestions
    ) {
      let currentChildQuestion = onboardingQuestions[
        currentQuestion
      ]?.childQuestions.find(
        (childQuestion) => childQuestion?.answer === answer
      );

      if (currentChildQuestion?.question) {
        const {
          question = "",
          questionType = "",
          options,
        } = currentChildQuestion.question;
        if (
          questionType === SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.INPUT_BOX
        ) {
          return (
            <InputQuestion
              question={question}
              onChangeAnswer={(answer) => this.handleOnChange(answer, 1)}
              answer={childAnswer ? childAnswer : ""}
            />
          );
        } else if (
          questionType ===
          SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.SINGLE_CHOICE
        ) {
          return (
            <RadioQuestions
              options={options}
              question={question}
              onChangeAnswer={(answer) => this.handleOnChange(answer, 1)}
              answer={childAnswer ? childAnswer : ""}
            />
          );
        } else if (
          questionType ===
          SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.MULTIPLE_CHOICE
        ) {
          return (
            <CheckboxQuestions
              options={options}
              question={question}
              onChangeAnswer={(answer) => this.handleOnChange(answer, 1)}
              answer={childAnswer && childAnswer.length > 0 ? childAnswer : []}
            />
          );
        } else if (
          questionType === SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.SELECT
        ) {
          return (
            <SelectboxQuestion
              options={options}
              question={question}
              onChangeAnswer={(answer) => this.handleOnChange(answer, 1)}
              answer={childAnswer ? childAnswer : ""}
            />
          );
        } else if (
          questionType === SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.MULTI_SELECT
        ) {
          return (
            <MultiSelectQuestion
              options={options}
              question={question}
              onChangeAnswer={(answer) => this.handleOnChange(answer, 1)}
              answer={childAnswer && childAnswer.length > 0 ? childAnswer : []}
            />
          );
        } else if (
          questionType === SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE.DATE
        ) {
          return (
            <DateQuestion
              options={options}
              question={question}
              onChangeAnswer={(answer) => this.handleOnChange(answer, 1)}
              answer={childAnswer}
            />
          );
        }
      }
    }
  };
  render() {
    const { isValid } = this.handleValidation();
    const { onboardingAnsData } = this.props;
    const { currentQuestion, onboardingQuestions } = this.state;
    return (
      <Box className="main-container">
        <Header
          data={{
            header: "On-Boarding Questions",
            page: PAGE_NAME.ONBOARDING_QUESTION,
            backUrl: `/app/postlogin/${PAGE_NAME.DASHBOARD}`,
          }}
        />
        <div className="view-questionnaires">
          <div className="survey-container">{this.renderQuestion()}</div>
          <div className="survey-container">{this.renderChildQuestion()}</div>
          {onboardingQuestions?.length ? (
            <div className="survey-buttons">
              <LoadingButton
                loading={onboardingAnsData.status === status.IN_PROGRESS}
                className="btn-primary"
                variant="contained"
                onClick={this.onClickNext}
                disabled={
                  onboardingAnsData.status === status.IN_PROGRESS || !isValid
                }
              >
                {onboardingAnsData.status === status.IN_PROGRESS
                  ? ""
                  : onboardingQuestions?.length - 1 === currentQuestion
                  ? "Submit"
                  : "Next"}
              </LoadingButton>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Box>
    );
  }
}

function mapStateToProps(state) {
  const { allOnBoardingQuestions, onboardingAnsData } = state.onboarding;
  return {
    allOnBoardingQuestions,
    onboardingAnsData,
  };
}

const mapDispatchToProps = {
  getOnboardingQuestions,
  saveOnboardingAnswer,
};

const connectedOnBoarding = connect(
  mapStateToProps,
  mapDispatchToProps
)(OnBoarding);
export default navigateRouter(connectedOnBoarding);
