import React, { Component } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

class DateQuestion extends Component {
  handleAnsChange = (date) => {
    this.props.onChangeAnswer(date);
  };
  render() {
    const { question, answer } = this.props;
    const newAns = answer ? dayjs(answer) : dayjs("");
    return (
      <div className="d-block choice-question-container">
        <div className="mb-3">
          <h3>{question}</h3>
          <span></span>
        </div>
        <div className="d-block date-picker-container">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="form-control"
              value={newAns}
              onChange={(e) => this.handleAnsChange(e)}
            />
          </LocalizationProvider>
        </div>
      </div>
    );
  }
}

export default DateQuestion;
