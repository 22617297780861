import React, { Component } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default class ConfirmationPopup extends Component {
  render() {
    return (
      <Dialog
        maxWidth="md"
        open={this.props.show}
        onClose={this.props.handleClose}
        className="common-modal-container"
      >
        <DialogTitle id="scroll-dialog-title heading">
          Delete Account
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={this.props.handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <p> Are you sure you want to delete account?</p>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            className="mt-0"
            variant="outlined"
            color="error"
            disabled={this.props.isLoading}
            type="submit"
            onClick={this.props.handleClose}
          >
            No
          </LoadingButton>
          <LoadingButton
            className="btn btn-primary"
            variant="contained"
            loading={this.props.isLoading}
            disabled={this.props.isLoading}
            type="submit"
            onClick={this.props.handlSubmit}
          >
          {this.props.isLoading ? '' :'Yes'}  
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}
