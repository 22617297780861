import React, { Component } from "react";
import { CustomRadio } from "Postlogin/Components/CustomRadio";
import FormHelperText from "@mui/material/FormHelperText";

export class CustomGrid extends Component {
  createTable = () => {
    const retData = [];
    const {
      location,
      ans = [],
      styleDetails = {},
      opts,
      isDisabled,
    } = this.props;
    const { samples, measurables } = opts;
    for (let i = 0; i < samples.length; i++) {
      const rowJSX = [];
      for (let j = 0; j < measurables.length; j++) {
        let isChecked = false;
        if (ans[i]) {
          let { measurable, sample } = ans[i];
          isChecked = measurable === measurables[j] && samples[i] === sample;
        }
        rowJSX.push(
          <td
            onClick={(e) =>
              !isDisabled
                ? this.handleStateChange(samples[i], measurables[j], i)
                : null
            }
          >
            <CustomRadio
              className="radio-buttons"
              checked={isChecked ? "checked" : ""}
              style={{
                boxShadow: `inset 0 0 0 2px ${styleDetails.radioBtnColor}`,
                backgroundImage: isChecked
                  ? `radial-gradient(${styleDetails.radioBtnColor},${styleDetails.radioBtnColor}
                 40%,
                transparent 44%
              ) `
                  : "",
              }}
            />
          </td>
        );
      }
      retData.push(
        <tr key={`grid-row-${i}-${location}`}>
          <td>
            <input
              value={samples[i]}
              readOnly
              type="text"
              style={{
                color: styleDetails?.optionColor,
              }}
            />
          </td>
          {rowJSX}
        </tr>
      );
    }
    return retData;
  };

  createColumnHeader = () => {
    const { opts, styleDetails = {} } = this.props;
    const { measurables } = opts;
    const retData = [];
    for (let i = 0; i < measurables.length; i++) {
      retData.push(
        <th
          style={{
            color: styleDetails?.optionColor,
          }}
        >
          {measurables[i]}
        </th>
      );
    }
    return retData;
  };

  handleStateChange = (sample, measurable, i) => {
    let { ans } = this.props;
    ans = ans?.length > 0 ? ans : [];
    ans[i] = {
      sample,
      measurable,
    };
    this.props.handleStateChange(ans);
  };

  render() {
    let {
      errorMsg,
      details,
      description,
      styleDetails = {},
      question,
    } = this.props;
    return (
      <div className="d-block choice-question-container">
        <div className="mb-3">
          <h3
            style={{
              color: styleDetails.questionColor,
              fontSize: `${styleDetails.questionFontSize}px`,
              fontFamily: styleDetails.questionFontFamily,
            }}
          >
            {question} {details?.isMandatory ? "*" : ""}
          </h3>
          <span
            style={{
              color: styleDetails.descriptionColor,
              fontSize: `${styleDetails.descriptionFontSize}px`,
              fontFamily: styleDetails.descriptionFontFamily,
            }}
          >
            {description}
          </span>
        </div>

        <div className="d-inline-block w-100 comparative-table">
          <table className="table text-center w-100">
            <thead>
              <tr>
                <th></th>
                {this.createColumnHeader()}
              </tr>
            </thead>
            <tbody>{this.createTable()}</tbody>
          </table>
        </div>
        {errorMsg ? (
          <FormHelperText
            className="MuiFormHelperText-root Mui-error"
            style={{
              color: styleDetails.errorColor,
              fontSize: styleDetails.errorFontSize,
              fontFamily: styleDetails.errorFontFamily,
            }}
          >
            {errorMsg}
          </FormHelperText>
        ) : (
          <></>
        )}
      </div>
    );
  }
}
