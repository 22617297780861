import React, { Component } from "react";
import Slider from "@mui/material/Slider";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";

const CustomSlider = styled(Slider)(({ theme, color }) => ({
  color: color,
  "& .MuiSlider-track": {
    border: "none",
    backgroundColor: "#434bdf",
  },
  "& .MuiSlider-thumb": {
    backgroundColor: "#434bdf",
    // backgroundColor: color,
    // border: "2px solid " + color,
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
      backgroundColor: "#434bdf",
    },
    "&::before": {
      display: "none",
    },
  },
}));

export class CustomLineScale extends Component {
  placeholders = ["Weak", "Strong"];

  handleStateChange = (e, value) => {
    this.props.handleStateChange(value);
  };

  render() {
    let {
      question,
      opts,
      errorMsg,
      ans = 0,
      details,
      description,
      styleDetails = {},
      isDisabled,
    } = this.props;
    return (
      <>
        <div className="d-block choice-question-container">
          <div className="mb-3">
            <h3
              style={{
                color: styleDetails.questionColor,
                fontSize: `${styleDetails.questionFontSize}px`,
                fontFamily: styleDetails.questionFontFamily,
              }}
            >
              {question} {details?.isMandatory ? "*" : ""}
            </h3>
            <span
              style={{
                color: styleDetails.descriptionColor,
                fontSize: `${styleDetails.descriptionFontSize}px`,
                fontFamily: styleDetails.descriptionFontFamily,
              }}
            >
              {description}
            </span>
          </div>

          <div className="d-block linescale-container">
            <CustomSlider
              // className="slider-pointer"
              size="small"
              aria-label="Dynamic Color Slider"
              value={ans}
              onChange={!isDisabled ? this.handleStateChange : null}
              color={styleDetails.lineScaleColor}
              style={{ color: styleDetails.lineScaleColor }}
              valueLabelDisplay="on"
            />
            <div className="d-inline-block weak-line-container">
              <div
                className="d-block weak-text"
                style={{ color: styleDetails.optionColor }}
              >
                {opts[0]}
              </div>
              <div className="d-block line">
                <span
                  style={{ backgroundColor: styleDetails.lineScaleColor }}
                ></span>
              </div>
              <div
                className="d-block number"
                style={{ color: styleDetails.optionColor }}
              >
                0
              </div>
            </div>

            <div className="d-inline-block strong-line-container">
              <div
                className="d-block strong-text"
                style={{ color: styleDetails.optionColor }}
              >
                {opts[1]}
              </div>
              <div className="d-block line">
                <span
                  style={{ backgroundColor: styleDetails.lineScaleColor }}
                ></span>
              </div>
              <div
                className="d-block number"
                style={{ color: styleDetails.optionColor }}
              >
                100
              </div>
            </div>
          </div>
          {errorMsg ? (
            <FormHelperText
              className="MuiFormHelperText-root Mui-error"
              style={{
                color: styleDetails.errorColor,
                fontSize: styleDetails.errorFontSize,
                fontFamily: styleDetails.errorFontFamily,
              }}
            >
              {errorMsg}
            </FormHelperText>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}
