import React, { Component } from "react";
import { OutlinedInput, FormControl } from "@mui/material";

export class CustomFreeText extends Component {
  handleStateChange(e) {
    const { value } = e.target;
    this.props.handleStateChange(value);
  }
  render() {
    let {
      question,
      ans = "",
      errorMsg,
      details,
      description,
      styleDetails = {},
    } = this.props;
    return (
      <div className="d-block choice-question-container">
        <div className="mb-3">
          <h3
            style={{
              color: styleDetails.questionColor,
              fontSize: `${styleDetails.questionFontSize}px`,
              fontFamily: styleDetails.questionFontFamily,
            }}
          >
            {question} {details?.isMandatory ? "*" : ""}
          </h3>
          <span
            style={{
              color: styleDetails.descriptionColor,
              fontSize: `${styleDetails.descriptionFontSize}px`,
              fontFamily: styleDetails.descriptionFontFamily,
            }}
          >
            {description}
          </span>
        </div>

        <div className="d-block input-choice-container">
          <FormControl variant="outlined" className="form-group w-100">
            <OutlinedInput
              style={{
                color: styleDetails?.optionColor,
              }}
              className="question-text form-control"
              placeholder="Type your answer"
              value={ans}
              onChange={(e) => this.handleStateChange(e)}
              disabled={this.props.isDisabled}
            />
            {errorMsg ? (
              <span
                style={{
                  color: styleDetails.errorColor,
                  fontSize: styleDetails.errorFontSize,
                  fontFamily: styleDetails.errorFontFamily,
                }}
              >
                {errorMsg}
              </span>
            ) : (
              <></>
            )}
          </FormControl>
        </div>
      </div>
    );
  }
}
