import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 0,
    width: 16,
    height: 16,
    boxShadow: "inset 0 0 0 2px #434bdf",
    backgroundColor: "trasnparent",

    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
    "input:checked ~ &": {
      boxShadow: "inset 0 0 0 1px #434bdf",
    },
  },
  checkedIcon: {
    backgroundColor: "#434bdf",

    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      marginTop: "-1px",
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#434bdf",
    },
  },
});

export function CustomCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      color="default"
      checkedIcon={
        <span
          style={props.style}
          className={clsx(classes.icon, classes.checkedIcon)}
        />
      }
      icon={<span style={props.style} className={classes.icon} />}
      {...props}
      style={{ boxShadow: "none" }}
    />
  );
}
