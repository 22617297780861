import React, { Component } from "react";
import StarIcon from "@mui/icons-material/Star";
import FavoriteIcon from "@mui/icons-material/Favorite";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import FormHelperText from "@mui/material/FormHelperText";
import Rating from "@mui/material/Rating";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MoodIcon from "@mui/icons-material/Mood";
import InsertEmoticonRoundedIcon from "@mui/icons-material/InsertEmoticonRounded";
import StarBorderIcon from "@mui/icons-material/StarBorder";

export class CustomRating extends Component {
  constructor(props) {
    super(props);
    this.factor = 1;
  }

  displayRatings = () => {
    let { ans = 0, styleDetails = {}, opts, icon, isDisabled } = this.props;
    let iconToDisplay = "";
    if (icon === "heart") {
      return (
        <div className="rating-icon">
          <Rating
            name="customized-10"
            value={ans}
            max={+opts.range}
            icon={
              <FavoriteIcon style={{ color: styleDetails?.ratingIconColor }} />
            }
            onChange={(event, newValue) =>
              !isDisabled ? this.handleStateChange(newValue) : null
            }
            emptyIcon={
              <FavoriteBorderIcon
                style={{ color: styleDetails?.ratingIconColor }}
              />
            }
          />
        </div>
      );
    } else if (icon === "smiley") {
      iconToDisplay = <InsertEmoticonIcon />;
      return (
        <div className="rating-icon">
          <Rating
            name="customized-10"
            value={ans}
            max={+opts.range}
            icon={
              <InsertEmoticonRoundedIcon
                style={{ color: styleDetails?.ratingIconColor }}
              />
            }
            onChange={(event, newValue) =>
              !isDisabled ? this.handleStateChange(newValue) : null
            }
            emptyIcon={
              <MoodIcon style={{ color: styleDetails?.ratingIconColor }} />
            }
          />
        </div>
      );
    } else if (icon === "star") {
      return (
        <div className="rating-icon">
          <Rating
            name="customized-10"
            value={ans}
            max={opts.range}
            onChange={(event, newValue) =>
              !isDisabled ? this.handleStateChange(newValue) : null
            }
            icon={<StarIcon style={{ color: styleDetails?.ratingIconColor }} />}
            emptyIcon={
              <StarBorderIcon
                style={{ color: styleDetails?.ratingIconColor }}
              />
            }
          />
        </div>
      );
    }
  };

  handleStateChange = (value) => {
    this.props.handleStateChange(value);
  };

  render() {
    let {
      errorMsg,
      details,
      description,
      styleDetails = {},
      question,
    } = this.props;
    return (
      <div className="d-block choice-question-container">
        <div className="mb-3">
          <h3
            style={{
              color: styleDetails.questionColor,
              fontSize: `${styleDetails.questionFontSize}px`,
              fontFamily: styleDetails.questionFontFamily,
            }}
          >
            {question} {details?.isMandatory ? "*" : ""}
          </h3>
          <span
            style={{
              color: styleDetails.descriptionColor,
              fontSize: `${styleDetails.descriptionFontSize}px`,
              fontFamily: styleDetails.descriptionFontFamily,
            }}
          >
            {description}
          </span>
        </div>

        <div className="d-flex rating-question">{this.displayRatings()}</div>
        {errorMsg ? (
          <FormHelperText
            className="MuiFormHelperText-root Mui-error"
            style={{
              color: styleDetails.errorColor,
              fontSize: styleDetails.errorFontSize,
              fontFamily: styleDetails.errorFontFamily,
            }}
          >
            {errorMsg}
          </FormHelperText>
        ) : (
          <></>
        )}
      </div>
    );
  }
}
