import React, { Component } from "react";
import { PAGE_NAME, status } from "Constants";
import Header from "Postlogin/Components/Header";

class index extends Component {
  render() {
    return (
      <>
        <Header
          data={{
            header: "Privacy Policy",
            page: PAGE_NAME.TERMS_AND_CONDITIONS,
            backUrl: `/auth/prelogin/register`,
          }}
        />
        <div className="termsAndConditions-container">
          <div className="termsAndConditions-inner-container">
            <p>
              <strong>Last Updated &#8282; </strong> October 03, 2024
            </p>
            <p>
              At SurvHaNa, we are committed to protecting your privacy. This
              Privacy Policy explains how we collect, use, disclose, and protect
              your information when you use our mobile application ("SurvHaNa
              Respondent Portal").
            </p>
            <h3>1. Information We Collect</h3>
            <p>
              We collect the following types of information to provide and
              improve our services:
            </p>
            <div className="d-block terms-content mb-3">
              <label>Personal Information &#8282; </label>
              <p>
                This includes details such as your name, email address, age,
                gender, location, and language preference, which are necessary
                to match you with appropriate surveys.
              </p>
            </div>
            <div className="d-block terms-content mb-3">
              <label>Survey Data &#8282; </label>
              <p>
                When you participate in surveys, we collect your responses,
                which may include opinions, habits, and other demographic or
                psychographic information.
              </p>
            </div>
            <div className="d-block terms-content mb-3">
              <label>Device Information &#8282; </label>
              <p>
                We may collect details about your device, such as the device
                model, operating system, and unique identifiers (like IP address
                and mobile device ID).
              </p>
            </div>
            <div className="d-block terms-content mb-3">
              <label>Location Information &#8282; </label>
              <p>
                If permitted by you, we collect precise location data to provide
                targeted surveys relevant to your region.
              </p>
            </div>
            <h3>2. How We Use Your Information</h3>
            <p>The information we collect is used to:</p>
            <ul>
              <li>
                Match you with relevant surveys based on your profile and
                preferences.
              </li>
              <li>Send notifications about surveys or app updates.</li>
              <li>
                Reward you for completing surveys with points, vouchers, or
                other rewards.
              </li>
              <li>Improve our app and develop new features.</li>
            </ul>
            <h3>3. Sharing Your Information</h3>
            <p>
              We respect your privacy and only share your data with trusted
              third parties under the following conditions:
            </p>
            <div className="d-block terms-content mb-3">
              <label>With Survey Providers &#8282; </label>
              <p>
                We share anonymized, aggregated survey responses with the
                organizations that publish surveys on SurvHaNa. Your identity
                will never be disclosed to these organizations.
              </p>
            </div>
            <div className="d-block terms-content mb-3">
              <label>Service Providers &#8282; </label>
              <p>
                We may share information with vendors who help us operate our
                app, such as payment processors and IT service providers,
                ensuring they follow strict privacy practices.
              </p>
            </div>
            <div className="d-block terms-content mb-3">
              <label>Legal Requirements &#8282; </label>
              <p>
                If required by law, we may disclose your information to comply
                with legal processes or to protect the rights and safety of our
                users and the public.
              </p>
            </div>
            <h3>4. Data Security</h3>
            <p>
              We employ industry-standard security measures to protect your data
              from unauthorized access, use, or disclosure. However, no system
              is completely secure, and we cannot guarantee the absolute
              security of your information.
            </p>
            <h3>5. Your Rights</h3>
            <p>You have the right to:</p>
            <ul>
              <li>Access, update, or delete your personal information.</li>
              <li>
                {" "}
                Withdraw consent for data collection at any time, although this
                may limit your ability to participate in surveys.
              </li>
              <li>
                Request a copy of your data in a structured, electronic format.
              </li>
            </ul>
            <p>
              To exercise these rights, please contact us at [email address].
            </p>
            <h3>6. Cookies and Tracking Technologies</h3>
            <p>
              Our app uses cookies and similar technologies to enhance your
              experience. These technologies help us understand how you use the
              app and provide personalized content. You can disable cookies in
              your device settings, though this may affect the functionality of
              the app.
            </p>
            <h3>7. Children’s Privacy</h3>
            <p>
              SurvHaNa is not intended for individuals under the age of 18. We
              do not knowingly collect personal information from children. If we
              discover that a child has provided us with personal data, we will
              take steps to delete such data.
            </p>
            <h3>8. Changes to This Policy</h3>
            <p>
              We may update this Privacy Policy from time to time. If we make
              significant changes, we will notify you through the app or via
              email.
            </p>
            <h3>8. Contact Us</h3>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us at:
            </p>
            <ul className="border-bottom pb-4">
              <li>
                <span> Email : </span>
                <a href="mailto: jasmin@survhana.com">jasmin@survhana.com</a>
              </li>
              <li>
                <span>Website : </span>
                <a href="https://www.survhana.com/organization">
                  www.survhana.com{" "}
                </a>
              </li>
            </ul>
            <strong>
              By using the SurvHaNa Respondent Portal, you consent to this
              Privacy Policy.
            </strong>
          </div>
        </div>
      </>
    );
  }
}

export default index;
