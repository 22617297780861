import React, { Component } from "react";
import { CustomRadio } from "Postlogin/Components/CustomRadio";
import { FormControl, OutlinedInput } from "@mui/material";

class RadioQuestions extends Component {
  renderRadios = (options, answer) => {
    return options.map((option) => {
      return (
        <FormControl variant="outlined" className="radio d-block form-group">
          <CustomRadio
            className="radio-buttons"
            value={option}
            checked={answer === option}
            onChange={(e) => {
              this.handleChange(e, option);
            }}
          />
          <OutlinedInput
            className="form-control"
            type="text"
            value={option}
            readOnly
            onClick={() => this.handleChange({ target: { checked: true } }, option)}
          />
        </FormControl>
      );
    });
  };

  handleChange = (e, option) => {
    const { checked } = e.target;
    let { answer } = this.props;
    if (checked) {
      answer = option;
    }
    this.props.onChangeAnswer(answer);
  };

  render() {
    const { question, options, answer } = this.props;
    return (
      <div className="d-block choice-question-container">
        <div className="mb-3">
          <h3>{question}</h3>
        </div>
        <div className="d-block radio-choice-container">
          {this.renderRadios(options, answer)}
        </div>
      </div>
    );
  }
}

export default RadioQuestions;
