import React, { Component } from "react";
import { CustomCheckbox } from "Postlogin/Components/CustomCheckbox";
import { FormControl, OutlinedInput } from "@mui/material";

class CheckboxQuestions extends Component {
  renderCheckboxes = (options, answer) => {
    return options.map((option) => {
      return (
        <FormControl variant="outlined" className="checkbox form-group ">
          <CustomCheckbox
            className="common-check-box"
            checked={answer.indexOf(option) !== -1}
            onChange={(e) => {
              this.handleChange(e, option);
            }}
            value={option}
          />
          <OutlinedInput
            className="form-control"
            type="text"
            value={option}
            onClick={() => {
              this.handleChange(
                {
                  target: {
                    checked: !answer.includes(option),
                  },
                },
                option
              );
            }}
            readOnly
          />
        </FormControl>
      );
    });
  };

  handleChange = (e, option) => {
    const { checked } = e.target;
    const { answer } = this.props;
    const index = answer.indexOf(option);
    if (checked) {
      if (index === -1) {
        answer.push(option);
      }
    } else {
      answer.splice(index, 1);
    }
    this.props.onChangeAnswer(answer);
  };

  render() {
    const { question, options, answer } = this.props;
    return (
      <div className="d-block choice-question-container">
        <div className="mb-3">
          <h3>{question}</h3>
          <span></span>
        </div>
        <div className="d-block checkbox-choice-container">
          {this.renderCheckboxes(options, answer)}
        </div>
      </div>
    );
  }
}

export default CheckboxQuestions;
