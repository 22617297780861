import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CustomDescriptionText,
  CustomMultipleChoice,
  CustomSingleChoice,
  CustomJar,
  CustomFreeText,
  CustomLineScale,
  CustomHeadonicScale,
  CustomGrid,
  CustomRating,
  CustomImageUpload,
  Welcome,
  ThankYou,
} from "./_answercomponents";
import { Button } from "@mui/material";
import {
  componentType,
  ORGANIZATION_PORTAL_URL,
  PAGE_NAME,
  status,
  COMPARATOR_TYPES,
  GOOGLE_ANALYTICS,
  LOCALSTORAGE_ITEMS,
} from "Constants";
import { getSurveyById, submitSurvey } from "ReduxStore/Survey/SurveyThunk";
import { navigateRouter } from "Utils/navigateRouter";
import Header from "Postlogin/Components/Header";
import { Loading } from "Postlogin/Components/Loading";
import { LoadingButton } from "@mui/lab";
import { alert } from "Utils/alert";
import axios from "axios";
import { Box } from "@mui/material";
import { GA } from "Utils/ga";
import { LocalStorageService } from "Utils/HelperFunctions";

class FillSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuestion: 0,
      surveyDetails: {},
      isWelcomePageShown: false,
      isThankYouPageShown: false,
      errorData: {
        isValid: true,
        message: "",
      },
      showSubmitBtn: false,
      isDisabled: false,
    };
  }

  componentDidMount() {
    const surveyId = this.props.params.surveyId;
    const token = this.props.params.token;
    if (token && surveyId) {
      this.getSurveyDetails(surveyId, token);
    } else if (surveyId) {
      this.props.getSurveyById(surveyId);
    }
    
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.surveyDetailsData.status !== prevProps.surveyDetailsData.status
    ) {
      if (this.props.surveyDetailsData.status === status.SUCCESS) {
        const surveyDetails = this.props.surveyDetailsData.data || {};
        let { isWelcomePageShown } = this.state;
        if (surveyDetails) {
          const { welcome } = surveyDetails;
          if (welcome?.header || welcome?.description || welcome?.image) {
            isWelcomePageShown = true;
          } else {
            isWelcomePageShown = false;
          }
        }
        const manipulatedData = this.manipulateData(
          JSON.parse(JSON.stringify(surveyDetails))
        );
        this.setState({
          surveyDetails: manipulatedData,
          currentQuestion: 0,
          isWelcomePageShown,
        });
      } else if (this.props.surveyDetailsData.status === status.FAILURE) {
        alert.error(this.props.surveyDetailsData.data);
      }
    }

    if (
      this.props.submittedSurveyData.status !==
      prevProps.submittedSurveyData.status
    ) {
      if (this.props.submittedSurveyData.status === status.SUCCESS) {
        const userData =
          JSON.parse(
            LocalStorageService.getData(LOCALSTORAGE_ITEMS.userData)
          ) || {};
        GA.dispatchGAEvent(
          GOOGLE_ANALYTICS.EVENT_CATEGORIES.RESPONDENT,
          GOOGLE_ANALYTICS.EVENT_ACTIONS.RESPONDENT_SURVEY_SUBMIT,
          `id=${userData._id}`
        );
        const { surveyDetails } = this.state;
        let outro = surveyDetails?.outro || {};
        let isOutroExist = outro?.header || outro?.description || outro?.image;
        if (isOutroExist) {
          this.setState({
            isWelcomePageShown: false,
            isThankYouPageShown: true,
          });
        } else {
          this.props.navigate(`/app/postlogin/${PAGE_NAME.DASHBOARD}`, {
            replace: true,
          });
        }
      } else if (this.props.submittedSurveyData.status === status.FAILURE) {
        alert.error(this.props.submittedSurveyData.data);
      }
    }
  }

  manipulateData = (surveyDetails) => {
    const { questions } = surveyDetails;
    questions.forEach((question) => {
      const { type } = question;
      if (type === componentType.MULTIPLE_CHOICE) {
        question.ans = [];
      } else if (
        type === componentType.FREE_TEXT ||
        type === componentType.SINGLE_CHOICE ||
        type === componentType.HEDONICSCALE ||
        type === componentType.JAR
      ) {
        question.ans = "";
      } else if (
        type === componentType.LINESCALE ||
        type === componentType.RATE
      ) {
        question.ans = 0;
      } else if (type === componentType.GRID) {
        question.ans = [];
      }
      question.isShow = true;
    });
    return surveyDetails;
  };

  handleStateChange = (ans) => {
    let { currentQuestion, surveyDetails } = this.state;

    const { questions } = surveyDetails;
    if (questions && questions.length > 0) {
      const question = questions[currentQuestion];
      question.ans = ans;
      this.setState({
        surveyDetails,
      });
    }
  };

  getSurveyDetails = async (surveyId, token) => {
    try {
      let response = await axios.get(
        `${ORGANIZATION_PORTAL_URL}/organization/survey/individual/${surveyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const surveyDetails = response.data.data.surveys || {};
      let { isWelcomePageShown } = this.state;

      if (surveyDetails) {
        const { welcome } = surveyDetails;
        if (welcome?.header || welcome?.description || welcome?.image) {
          isWelcomePageShown = true;
        } else {
          isWelcomePageShown = false;
        }
      }
      const manipulatedData = this.manipulateData(
        JSON.parse(JSON.stringify(surveyDetails))
      );
      this.setState({
        surveyDetails: manipulatedData,
        currentQuestion: 0,
        isWelcomePageShown,
        isDisabled: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  renderQuestions = () => {
    const {
      currentQuestion,
      surveyDetails,
      isThankYouPageShown,
      isWelcomePageShown,
      errorData,
    } = this.state;
    if (isWelcomePageShown) {
      return (
        <Welcome
          data={surveyDetails?.welcome || {}}
          onClickStart={() => {
            this.setState({ isWelcomePageShown: false });
          }}
        />
      );
    } else if (isThankYouPageShown) {
      return <ThankYou data={surveyDetails?.outro || {}} />;
    } else {
      if (surveyDetails.questions?.length > 0) {
        const question = surveyDetails.questions[currentQuestion];
        const styleDetails = surveyDetails.styleDetails
          ? surveyDetails.styleDetails
          : {};
        const type = question.type;

        if (type === componentType.DESCRIPTION && question.isShow) {
          return (
            <CustomDescriptionText
              styleDetails={styleDetails}
              question={question.question}
            />
          );
        } else if (type === componentType.MULTIPLE_CHOICE && question.isShow) {
          return (
            <CustomMultipleChoice
              {...question}
              styleDetails={styleDetails}
              handleStateChange={this.handleStateChange}
              errorMsg={errorData.message}
              isValid={errorData.isValid}
              isDisabled={this.state.isDisabled}
            />
          );
        } else if (type === componentType.FREE_TEXT && question.isShow) {
          return (
            <CustomFreeText
              {...question}
              styleDetails={styleDetails}
              handleStateChange={this.handleStateChange}
              errorMsg={errorData.message}
              isValid={errorData.isValid}
              isDisabled={this.state.isDisabled}
            />
          );
        } else if (type === componentType.SINGLE_CHOICE && question.isShow) {
          return (
            <CustomSingleChoice
              {...question}
              styleDetails={styleDetails}
              handleStateChange={this.handleStateChange}
              errorMsg={errorData.message}
              isValid={errorData.isValid}
              isDisabled={this.state.isDisabled}
            />
          );
        } else if (type === componentType.RATE && question.isShow) {
          return (
            <CustomRating
              {...question}
              styleDetails={styleDetails}
              handleStateChange={this.handleStateChange}
              errorMsg={errorData.message}
              isValid={errorData.isValid}
              isDisabled={this.state.isDisabled}
            />
          );
        } else if (type === componentType.GRID && question.isShow) {
          return (
            <CustomGrid
              {...question}
              styleDetails={styleDetails}
              handleStateChange={this.handleStateChange}
              errorMsg={errorData.message}
              isValid={errorData.isValid}
              isDisabled={this.state.isDisabled}
            />
          );
        } else if (type === componentType.LINESCALE && question.isShow) {
          return (
            <CustomLineScale
              {...question}
              styleDetails={styleDetails}
              handleStateChange={this.handleStateChange}
              errorMsg={errorData.message}
              isValid={errorData.isValid}
              isDisabled={this.state.isDisabled}
            />
          );
        } else if (type === componentType.HEDONICSCALE && question.isShow) {
          return (
            <CustomHeadonicScale
              {...question}
              styleDetails={styleDetails}
              handleStateChange={this.handleStateChange}
              errorMsg={errorData.message}
              isValid={errorData.isValid}
              isDisabled={this.state.isDisabled}
            />
          );
        } else if (type === componentType.JAR && question.isShow) {
          return (
            <CustomJar
              {...question}
              styleDetails={styleDetails}
              handleStateChange={this.handleStateChange}
              errorMsg={errorData.message}
              isValid={errorData.isValid}
              isDisabled={this.state.isDisabled}
            />
          );
        } else if (type === componentType.IMAGE_UPLOAD && question.isShow) {
          return (
            <CustomImageUpload
              {...question}
              styleDetails={styleDetails}
              handleStateChange={this.handleStateChange}
              errorMsg={errorData.message}
              isValid={errorData.isValid}
              isDisabled={this.state.isDisabled}
            />
          );
        }
      }
      return <></>;
    }
  };

  validateCurrentQuestion = () => {
    let errorData = {
      isValid: true,
      message: "",
    };
    const { surveyDetails, currentQuestion } = this.state;
    const { questions } = surveyDetails;
    if (questions[currentQuestion]) {
      const question = questions[currentQuestion];
      const { ans, details, type, opts } = question;
      if (details) {
        const {
          mandatoryMessage,
          isMandatory,
          isCaseSensitive,
          validationRegex,
          validationMessage,
        } = details;
        if (isMandatory) {
          if (type === componentType.MULTIPLE_CHOICE) {
            if (!ans || (ans && ans.length === 0)) {
              errorData = {
                isValid: false,
                message: mandatoryMessage,
              };
            }
          } else if (
            type === componentType.FREE_TEXT ||
            type === componentType.SINGLE_CHOICE ||
            type === componentType.HEDONICSCALE ||
            type === componentType.JAR
          ) {
            if (!ans || ans?.trim() === "") {
              errorData = {
                isValid: false,
                message: mandatoryMessage,
              };
            }
          } else if (
            type === componentType.LINESCALE ||
            type === componentType.RATE
          ) {
            if (!ans || ans === 0) {
              errorData = {
                isValid: false,
                message: mandatoryMessage,
              };
            }
          } else if (type === componentType.GRID) {
            if (!ans || (ans && ans.length !== opts.samples.length)) {
              errorData = {
                isValid: false,
                message: mandatoryMessage,
              };
            }
          }
        }
        if (errorData.isValid && validationRegex) {
          if (type === componentType.FREE_TEXT) {
            const regex = isCaseSensitive
              ? new RegExp(`^${details?.validationRegex}$`)
              : new RegExp(`^${details?.validationRegex}$`, "i");
            const isValid = regex.test(ans);
            if (!isValid) {
              errorData = {
                isValid,
                message: validationMessage,
              };
            }
          }
        }
      }
    }

    return errorData;
  };

  onClickNext = async () => {
    const { currentQuestion, surveyDetails, isWelcomePageShown } = this.state;
    let outro = surveyDetails?.outro || {};
    let isOutroExist = outro?.header || outro?.description || outro?.image;

    if (isWelcomePageShown) {
      this.setState({
        isWelcomePageShown: false,
        currentQuestion: 0,
      });
      return;
    }
    if (!this.state.isDisabled) {
      const errorData = this.validateCurrentQuestion();
      if (errorData.isValid) {
        await this.viewLogicImplement();
        this.setState({
          errorData,
        });
      } else {
        this.setState({
          errorData,
        });
      }
    } else if (currentQuestion !== surveyDetails.questions.length - 1) {
      this.setState({ currentQuestion: currentQuestion + 1 });
    } else if (isOutroExist && this.state.isDisabled) {
      this.setState({ isThankYouPageShown: true });
    }
  };

  onClickPrevious = () => {
    let {
      currentQuestion,
      surveyDetails,
      isWelcomePageShown,
      isThankYouPageShown,
      isDisabled,
    } = this.state;
    const { questions, welcome } = surveyDetails;
    if (isThankYouPageShown && isDisabled) {
      this.setState({ isThankYouPageShown: false });
      return;
    }
    if (currentQuestion === 0) {
      if (welcome?.header || welcome?.description || welcome?.image) {
        isWelcomePageShown = true;
      } else {
        isWelcomePageShown = false;
      }
    }
    this.setState({ isWelcomePageShown });

    if (currentQuestion - 1 >= 0) {
      if (!questions[currentQuestion - 1]?.isShow) {
        for (
          let currentIndex = this.state.currentQuestion - 1;
          currentIndex > 0;
          currentIndex--
        ) {
          const element = questions[currentIndex];
          if (element.isShow) {
            currentQuestion = currentIndex;
            break;
          }
        }
      } else {
        currentQuestion = currentQuestion - 1;
      }
      this.setState(
        {
          currentQuestion,
          showSubmitBtn: false,
        },
        () => {
          if (!this.state.isDisabled) {
            const errorData = this.validateCurrentQuestion();
            this.setState({ errorData });
          }
        }
      );
      return;
    }
  };

  onClickBackToDashboard = () => {
    // redirect user to dashboard
    this.props.navigate("/app/postlogin/dashboard", { replace: true });
  };

  viewLogicImplement = async () => {
    let { surveyDetails, showSubmitBtn } = this.state;
    if (surveyDetails?.questions?.length) {
      let currentQuestion =
        surveyDetails?.questions?.[this.state.currentQuestion];

      let questions = JSON.parse(
        JSON.stringify(surveyDetails?.questions || [])
      );

      if (questions?.length) {
        questions = questions.map((question) => {
          if (question?.display?.length) {
            let isShow = this.setLogicViewHide(
              question.display,
              currentQuestion?._id,
              currentQuestion?.ans || "",
              currentQuestion?.type
            );

            if (isShow === false || isShow === true) {
              question["isShow"] = isShow;
            }
          }

          if (question?.skip?.length) {
            let isHide = this.setLogicViewHide(
              question.skip,
              currentQuestion?._id,
              currentQuestion?.ans || "",
              currentQuestion?.type
            );

            if (isHide === false || isHide === true) {
              question["isShow"] = isHide ? false : true;
            }
          }

          return question;
        });
      }

      if (this.state.currentQuestion + 1 < questions.length) {
        if (!questions[this.state.currentQuestion + 1]?.isShow) {
          let showIndex = "";
          for (
            let currentIndex = this.state.currentQuestion + 1;
            currentIndex < questions.length;
            currentIndex++
          ) {
            const element = questions[currentIndex];
            if (element.isShow) {
              showIndex = currentIndex;
              showSubmitBtn = false;
              break;
            } else {
              questions = questions.map((question) => {
                if (question?.display?.length) {
                  let isShow = this.setLogicViewHide(
                    question.display,
                    questions[currentIndex]?._id,
                    questions[currentIndex]?.ans,
                    questions[currentIndex]?.type
                  );

                  if (isShow === false || isShow === true) {
                    question["isShow"] = isShow;
                  }
                }

                if (question?.skip?.length) {
                  let isHide = this.setLogicViewHide(
                    question.skip,
                    questions[currentIndex]?._id,
                    questions[currentIndex]?.ans,
                    questions[currentIndex]?.type
                  );

                  if (isHide === false || isHide === true) {
                    question["isShow"] = isHide ? false : true;
                  }
                }

                return question;
              });
              if (currentIndex === questions.length - 1) {
                showSubmitBtn = true;
              }
            }
          }

          if (showIndex) {
            this.setState({
              currentQuestion: showIndex,
              showSubmitBtn,
            });
          } else if (showSubmitBtn) {
            questions = questions.map((question) => {
              delete question.isShow;
              return question;
            });
            const sendData = {
              questions,
              surveyId: surveyDetails._id,
            };

            this.props.submitSurvey(sendData);
          }
        } else {
          this.setState({
            currentQuestion: this.state.currentQuestion + 1,
          });
        }
      }

      if (this.state.currentQuestion === questions.length - 1) {
        questions = questions.map((question) => {
          delete question.isShow;
          return question;
        });
        const sendData = {
          questions,
          surveyId: surveyDetails._id,
        };
        this.props.submitSurvey(sendData);
        // Call the api to save the whole data.
      }
      let remainingQuestions = questions?.slice(this.state.currentQuestion + 1);

      if (remainingQuestions?.length) {
        remainingQuestions.forEach((remainingQuestion) => {
          questions = questions.map((question) => {
            if (question?.display?.length) {
              let isShow = this.setLogicViewHide(
                question.display,
                remainingQuestion?._id,
                remainingQuestion?.ans,
                remainingQuestion?.type
              );

              if (isShow === false || isShow === true) {
                question["isShow"] = isShow;
              }
            }

            if (question?.skip?.length) {
              let isHide = this.setLogicViewHide(
                question.skip,
                remainingQuestion?._id,
                remainingQuestion?.ans,
                remainingQuestion?.type
              );

              if (isHide === false || isHide === true) {
                question["isShow"] = isHide ? false : true;
              }
            }

            return question;
          });
        });
      }
      showSubmitBtn = remainingQuestions?.length
        ? questions
            ?.slice(this.state.currentQuestion + 2)
            .every((question) => question.isShow === false)
        : showSubmitBtn;
      // clear data when isShow = false
      questions = questions.map((question) => {
        if (question.isShow === false) {
          const { type } = question;
          if (type === componentType.MULTIPLE_CHOICE) {
            question.ans = [];
          } else if (
            type === componentType.FREE_TEXT ||
            type === componentType.SINGLE_CHOICE ||
            type === componentType.HEDONICSCALE ||
            type === componentType.JAR
          ) {
            question.ans = "";
          } else if (
            type === componentType.LINESCALE ||
            type === componentType.RATE
          ) {
            question.ans = 0;
          } else if (type === componentType.GRID) {
            question.ans = [];
          }
        }
        return question;
      });
      this.setState({
        surveyDetails: {
          ...this.state.surveyDetails,
          questions,
        },
        showSubmitBtn,
      });
    }
    return 1;
  };

  setLogicViewHide = (data, id, ans, type) => {
    let ansLogicalArr = [];
    if (data?.length) {
      data.forEach((logic, index) => {
        let isValid = "";
        if (logic.question && logic.question === id) {
          if (logic.comparator === COMPARATOR_TYPES?.IS_ANSWERED?.value) {
            if (
              [componentType.MULTIPLE_CHOICE, componentType.GRID].includes(type)
            ) {
              if (ans?.length) {
                isValid = true;
              } else {
                isValid = false;
              }
            } else if (
              [
                componentType.FREE_TEXT,
                componentType.SINGLE_CHOICE,
                componentType.HEDONICSCALE,
                componentType.JAR,
              ].includes(type)
            ) {
              if (ans) {
                isValid = true;
              } else {
                isValid = false;
              }
            } else if (
              [componentType.LINESCALE, componentType.RATE].includes(type)
            ) {
              if (ans && +ans > 0) {
                isValid = true;
              } else {
                isValid = false;
              }
            }
            // is not answered
          } else if (
            logic.comparator === COMPARATOR_TYPES?.IS_NOT_ANSWERED?.value
          ) {
            if (
              [componentType.MULTIPLE_CHOICE, componentType.GRID].includes(type)
            ) {
              if (!ans || ans?.length === 0) {
                isValid = true;
              } else {
                isValid = false;
              }
            } else if (
              [
                componentType.FREE_TEXT,
                componentType.SINGLE_CHOICE,
                componentType.HEDONICSCALE,
                componentType.JAR,
              ].includes(type)
            ) {
              if (!ans) {
                isValid = true;
              } else {
                isValid = false;
              }
            } else if (
              [componentType.LINESCALE, componentType.RATE].includes(type)
            ) {
              if (!ans || ans <= 0) {
                isValid = true;
              } else {
                isValid = false;
              }
            }
          } else if (logic.comparator === COMPARATOR_TYPES?.CONTAINS?.value) {
            if (ans && ans?.includes(logic?.value)) {
              isValid = true;
            } else {
              isValid = false;
            }
          } else if (
            logic.comparator === COMPARATOR_TYPES?.DOES_NOT_CONTAIN?.value
          ) {
            if (ans && !ans?.includes(logic?.value)) {
              isValid = true;
            } else {
              isValid = false;
            }
          } else if (logic.comparator === COMPARATOR_TYPES?.EQUALS?.value) {
            if (ans && String(ans) === String(logic?.value)) {
              isValid = true;
            } else {
              isValid = false;
            }
          } else if (
            logic.comparator === COMPARATOR_TYPES?.IS_NOT_EQUAL_TO?.value
          ) {
            if (ans && String(ans) !== String(logic?.value)) {
              isValid = true;
            } else {
              isValid = false;
            }
          } else if (
            logic.comparator === COMPARATOR_TYPES?.STARTS_WITH?.value
          ) {
            if (String(ans).startsWith(String(logic.value))) {
              isValid = true;
            } else {
              isValid = false;
            }
          } else if (logic.comparator === COMPARATOR_TYPES?.ENDS_WITH?.value) {
            if (String(ans).endsWith(String(logic.value))) {
              isValid = true;
            } else {
              isValid = false;
            }
          } else if (logic.comparator === COMPARATOR_TYPES?.SELECTS?.value) {
            if ([componentType.MULTIPLE_CHOICE].includes(type)) {
              if (ans?.length) {
                isValid = ans.some((value) => logic?.value.includes(value));
              } else {
                isValid = false;
              }
            } else if ([componentType.SINGLE_CHOICE].includes(type)) {
              if (logic?.value?.includes(ans)) {
                isValid = true;
              } else {
                isValid = false;
              }
            }
          } else if (logic.comparator === COMPARATOR_TYPES?.NOT_SELECT?.value) {
            if ([componentType.MULTIPLE_CHOICE].includes(type)) {
              if (ans?.length) {
                isValid = ans.some((value) => !logic?.value.includes(value));
              } else {
                isValid = false;
              }
            } else if ([componentType.SINGLE_CHOICE].includes(type)) {
              if (!logic?.value?.includes(ans)) {
                isValid = true;
              } else {
                isValid = false;
              }
            }
          } else if (logic.comparator === COMPARATOR_TYPES?.IN_BETWEEN?.value) {
            if (+logic?.value?.[0] < ans && +logic?.value?.[1] > ans) {
              isValid = true;
            } else {
              isValid = false;
            }
          } else if (
            logic.comparator === COMPARATOR_TYPES?.IS_NOT_IN_BETWEEN?.value
          ) {
            if (!(+logic?.value?.[0] < ans && +logic?.value?.[1] > ans)) {
              isValid = true;
            } else {
              isValid = false;
            }
          }
        }
        if (data.length - 1 !== index) {
          let logicalCheck =
            logic.join === 0 ? "&&" : logic.join === 1 ? "||" : "";
          if (isValid === true || isValid === false) {
            ansLogicalArr.push(isValid);
            if (logicalCheck) {
              ansLogicalArr.push(logicalCheck);
            }
          }
        } else if (data.length - 1 === index) {
          if (isValid === true || isValid === false) {
            ansLogicalArr.push(isValid);
          }
        }
      });
    }

    return ansLogicalArr.length ? this.evaluateLogicalArray(ansLogicalArr) : "";
  };
  evaluateLogicalArray = (arr) => {
    // Start with the first boolean value as the initial result
    let result = arr[0];
    // Iterate over the array, skipping the boolean values and applying operators
    for (let i = 1; i < arr.length; i += 2) {
      let operator = arr[i];
      let nextValue = arr[i + 1];
      if (operator === "&&") {
        result = result && nextValue;
      } else if (operator === "||") {
        result = result || nextValue;
      }
    }
    return result;
  };
  render() {
    const { surveyDetailsData } = this.props;
    let {
      surveyDetails,
      isWelcomePageShown,
      isThankYouPageShown,
      currentQuestion,
      showSubmitBtn,
    } = this.state;
    let outro = surveyDetails?.outro || {};
    let isOutroExist = outro?.header || outro?.description || outro?.image;

    return (
      <Box className="main-container">
        <Header
          data={{
            header: surveyDetails.surveyName,
            page: PAGE_NAME.SURVEY,
            backUrl: `/app/postlogin/${PAGE_NAME.SURVEY_AVAILABLE}`,
          }}
        />
        {surveyDetailsData.status === status.IN_PROGRESS ? (
          <div
            className="text-center d-flex align-items-center justify-content-center"
            style={{ height: "83vh" }}
          >
            <Loading />
          </div>
        ) : (
          <>
            {surveyDetailsData.status === status.SUCCESS ||
            this.props.params.token ? (
              <div className="view-questionnaires">
                <div className="survey-container">{this.renderQuestions()}</div>
                <div className="survey-buttons" justifyContent={"flex-end"}>
                  {isWelcomePageShown ||
                  (isThankYouPageShown && !this.state.isDisabled) ? (
                    <></>
                  ) : (
                    <Button
                      className="btn-primary-outlined me-3"
                      variant="outlined"
                      onClick={this.onClickPrevious}
                    >
                      Previous
                    </Button>
                  )}
                  {isThankYouPageShown && !this.state.isDisabled ? (
                    <Button
                      style={{ width: "auto" }}
                      className="btn-primary-outlined px-5"
                      variant="outlined"
                      onClick={this.onClickBackToDashboard}
                    >
                      Back To Dashboard
                    </Button>
                  ) : isWelcomePageShown ||
                    (this.props.params.token &&
                      currentQuestion === surveyDetails.questions?.length - 1 &&
                      ((this.state.isDisabled && !isOutroExist) ||
                        (this.state.isDisabled && isThankYouPageShown))) ? (
                    <></>
                  ) : (
                    <LoadingButton
                      className="btn-primary"
                      variant="contained"
                      onClick={this.onClickNext}
                      disabled={
                        this.props.submittedSurveyData.status ===
                        status.IN_PROGRESS
                      }
                      loading={
                        this.props.submittedSurveyData.status ===
                        status.IN_PROGRESS
                      }
                    >
                      {(currentQuestion ===
                        surveyDetails.questions?.length - 1 ||
                        showSubmitBtn) &&
                      !this.state.isDisabled
                        ? "Submit"
                        : "Next"}
                    </LoadingButton>
                  )}
                </div>
              </div>
            ) : null}
          </>
        )}
      </Box>
    );
  }
}

function mapStateToProps(state) {
  const { surveyDetailsData, submittedSurveyData } = state.survey;
  return {
    surveyDetailsData,
    submittedSurveyData,
  };
}

const mapDispatchToProps = {
  getSurveyById,
  submitSurvey,
};

const connectedFillSurvey = connect(
  mapStateToProps,
  mapDispatchToProps
)(FillSurvey);
export default navigateRouter(connectedFillSurvey);
