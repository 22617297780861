import React, { Component } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import TermsAndConditions from "Prelogin/TermsAndConditions";
import PrivacyPolicy from "Prelogin/PrivacyPolicy";
import { LocalStorageService } from "Utils/HelperFunctions";
import { FCM_TOKEN } from "Constants";
const Login = React.lazy(() => import("Prelogin/Login"));
const Register = React.lazy(() => import("Prelogin/Register"));

class LoginLayout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Routes>
        <Route
          path="*"
          element={<Navigate to={`prelogin/login/`} replace={true} />}
        />
        <Route
          path="/prelogin/login/"
          exact={true}
          name={Login}
          element={<Login />}
        />
        <Route
          path="/prelogin/register"
          exact={true}
          name={Register}
          element={<Register />}
        />
        <Route
          path="/prelogin/termsandconditions"
          element={<TermsAndConditions />}
        />
        <Route path="/prelogin/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

const connectedLoginLayout = connect(mapStateToProps)(LoginLayout);
export { connectedLoginLayout as LoginLayout };
