import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./Auth/AuthSlice";
import SurveySlice from "./Survey/SurveySlice";
import OnboardingSlice from "./Onboarding/OnboardingSlice";
import ProfileSlice from "./Profile/ProfileSlice";
import DashboardSlice from "./Dashboard/DashboardSlice";
import ChangePasswordSlice from "./ChangePassword/ChangePasswordSlice";
import BankDetailsSlice from "./BankDetails/BankDetailsSlice";

const store = configureStore({
  reducer: {
    auth: AuthSlice,
    survey: SurveySlice,
    onboarding: OnboardingSlice,
    profile: ProfileSlice,
    dashboard: DashboardSlice,
    changePassword: ChangePasswordSlice,
    bankDetails: BankDetailsSlice,
  },
});

export default store;
