import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Radio from "@mui/material/Radio";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 27,
    height: 27,
    boxShadow: "inset 0 0 0 2px #434bdf",
    backgroundColor: "transparent",

    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "#ABABAB",
    },
  },
  checkedIcon: {
    backgroundColor: "trasnparent",
    "&:before": {
      display: "block",
      width: 27,
      height: 27,
      backgroundImage: "radial-gradient(#434bdf,#434bdf 40%,transparent 44%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "transparent",
    },
  },
});

// Inspired by blueprintjs
export function CustomRadio(props) {
  const classes = useStyles();
  return (
    <Radio
      className={classes.root}
      color="default"
      checkedIcon={
        <span
          style={props.style}
          className={clsx(classes.icon, classes.checkedIcon)}
        />
      }
      icon={<span style={props.style} className={classes.icon} />}
      {...props}
      style={{ boxShadow: "none" }}
    />
  );
}
