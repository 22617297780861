import { createSlice } from "@reduxjs/toolkit";
import { getAllSurvey, getSurveyById, submitSurvey } from "./SurveyThunk";
import { status } from "Constants";

const DashboardSlice = createSlice({
  name: "survey",
  initialState: {
    allSurveyData: {
      status: null,
      data: [],
    },
    surveyDetailsData: {
      status: null,
      data: {},
    },
    submittedSurveyData: {
      status: null,
      data: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSurvey.pending.toString(), (state, action) => {
        return {
          ...state,
          allSurveyData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(getAllSurvey.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          allSurveyData: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(getAllSurvey.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          allSurveyData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(getSurveyById.pending.toString(), (state, action) => {
        return {
          ...state,
          surveyDetailsData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(getSurveyById.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          surveyDetailsData: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(getSurveyById.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          surveyDetailsData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(submitSurvey.pending.toString(), (state, action) => {
        return {
          ...state,
          submittedSurveyData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(submitSurvey.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          submittedSurveyData: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(submitSurvey.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          submittedSurveyData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      });
  },
});

export default DashboardSlice.reducer;
