import React, { Component } from "react"

export class CustomDescriptionText extends Component {
  render() {
    const { styleDetails, question } = this.props;
    return (
      <>
        <div className="d-block description-box">
          <p
            style={{
              color: styleDetails.descriptionColor,
              fontSize: `${styleDetails.descriptionFontSize}px`,
              fontFamily: styleDetails.descriptionFontFamily,
            }}
          >
            {question}
          </p>
        </div>
      </>
    );
  }
};

