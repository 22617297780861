import React from "react";
import { makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";

const useStylesBootstrap = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: "#ffffff",
    fontFamily: "Open Sans",
    fontWeight: "500",
    color: "#212529",
    fontSize: 12,
    borderColor: "#333951",
    borderStyle: "solid",
    borderWidth: "1px",
    padding: "7px 10px",
    lineHeight: "18px",
    width: "250px",
  },
}));

export function QuestionnaireTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip classes={classes} {...props} />;
}
