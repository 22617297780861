import React, { Component } from "react";
import {
  GOOGLE_ANALYTICS,
  LOCALSTORAGE_ITEMS,
  PAGE_NAME,
  status,
} from "Constants";
import { navigateRouter } from "Utils/navigateRouter";
import Header from "Postlogin/Components/Header";
import { Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AvatarImg from "../../assets/img/login.png";
import { LocalStorageService, ValidationEngine } from "Utils/HelperFunctions";
import LoadingButton from "@mui/lab/LoadingButton";
import { connect } from "react-redux";
import { handleChangePassword } from "ReduxStore/ChangePassword/ChangePasswordThunk";
import { alert } from "Utils/alert";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { GA } from "Utils/ga";

const validationSchema = {
  password: [
    {
      message: "Please enter Password",
      type: ValidationEngine.type.MANDATORY,
    },
  ],
  confirmPassword: [
    {
      message: "Please enter valid confirm password",
      type: ValidationEngine.type.MANDATORY,
    },
  ],
};
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showConfirmPassword: false,
      password: "",
      confirmPassword: "",
      isSubmitted: false,
    };
    this.userData =
      JSON.parse(LocalStorageService.getData(LOCALSTORAGE_ITEMS.userData)) ||
      {};
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.changePasswordData.status !==
      prevProps.changePasswordData.status
    ) {
      if (this.props.changePasswordData.status === status.SUCCESS) {
        let response = this.props.changePasswordData.data || {};
        if (response?.status) {
          const userData =
            JSON.parse(
              LocalStorageService.getData(LOCALSTORAGE_ITEMS.userData)
            ) || {};
          GA.dispatchGAEvent(
            GOOGLE_ANALYTICS.EVENT_CATEGORIES.RESPONDENT,
            GOOGLE_ANALYTICS.EVENT_ACTIONS.RESPONDENT_CHANGE_PASSWORD,
            `id=${userData._id}`
          );
          this.props.navigate("/app/postlogin/dashboard");
          alert.success(response?.message);
        } else {
          alert.error(response?.message);
        }
      } else if (this.props.changePasswordData.status === status.FAILURE) {
        alert.error(
          this.props.changePasswordData?.data === "Rejected"
            ? "There is some internal server error."
            : this.props.changePasswordData?.data
        );
      }
    }
  };
  validateForm = () => {
    const { password, confirmPassword } = this.state;
    const error = ValidationEngine.validate(validationSchema, {
      password,
      confirmPassword: confirmPassword === password ? confirmPassword : "",
    });
    return error;
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState(
      {
        isSubmitted: true,
      },
      () => {
        const errors = this.validateForm();
        if (errors.isValid) {
          const { password } = this.state;
          this.props.handleChangePassword({
            password,
            respondentId: this.userData?._id,
          });
        }
      }
    );
  };
  render() {
    const {
      password,
      confirmPassword,
      isSubmitted,
      showPassword,
      showConfirmPassword,
    } = this.state;
    const errorData = this.validateForm();
    let { changePasswordData } = this.props;
    return (
      <Box className="main-container">
        <Header
          data={{
            header: "Change Password",
            page: PAGE_NAME.CHANGE_PASSWORD,
            backUrl: `/app/postlogin/${PAGE_NAME.DASHBOARD}`,
          }}
        />
        <div
          className="profile-container d-flex align-items-center justify-content-center"
          style={{ height: "84vh" }}
        >
          <div className="profile-inner">
            <div className="avatar-img">
              <Box position={"relative"}>
                <Avatar src={AvatarImg} sx={{ width: 96, height: 96 }}></Avatar>
                <IconButton aria-label="delete" className="btn-primary">
                  <EditIcon />
                </IconButton>
              </Box>
            </div>
            <form className="w-100" onSubmit={this.handleSubmit}>
              <div className="mb-3 form-group">
                <label for="exampleInputPassword1" className="form-label">
                  Password
                </label>
                <input
                  id="exampleInputPassword1"
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                />
                <i
                  className={`fa-regular ${
                    showPassword ? "fa-eye" : " fa-eye-slash"
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ showPassword: !showPassword });
                  }}
                ></i>
                {isSubmitted && !errorData?.password?.isValid ? (
                  <span style={{ color: "red" }}>
                    {errorData?.password?.message}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-3 form-group">
                <label for="exampleInputPassword1" className="form-label">
                  Confirm Password
                </label>
                <input
                  id="exampleInputPassword1"
                  type={showConfirmPassword ? "text" : "password"}
                  className="form-control"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={this.handleChange}
                />
                <i
                  className={`fa-regular ${
                    showConfirmPassword ? "fa-eye" : " fa-eye-slash"
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                      showConfirmPassword: !showConfirmPassword,
                    });
                  }}
                ></i>
                {isSubmitted && !errorData?.confirmPassword?.isValid ? (
                  <span style={{ color: "red" }}>
                    {errorData?.confirmPassword?.message}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div
                id="bottom-wizard"
                className="d-flex align-item-center justify-content-start "
              >
                <LoadingButton
                  className="btn btn-primary rounded px-4 btn-md w-100"
                  variant="contained"
                  loading={changePasswordData.status === status.IN_PROGRESS}
                  disabled={changePasswordData.status === status.IN_PROGRESS}
                  type="submit"
                >
                  {changePasswordData.status !== status.IN_PROGRESS &&
                    "Change Password"}
                </LoadingButton>
              </div>
            </form>
          </div>
        </div>
      </Box>
    );
  }
}

function mapStateToProps(state) {
  const { changePasswordData } = state.changePassword;
  return { changePasswordData };
}

const mapDispatchToProps = {
  handleChangePassword,
};

const connectedChangePassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);

export default navigateRouter(connectedChangePassword);
