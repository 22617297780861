import { createSlice } from "@reduxjs/toolkit";
import {
  getAllSurvey,
  getSurveyById,
  getDashboardDetails,
} from "./DashboardThunk";
import { status } from "Constants";

const DashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    allSurveyData: {
      status: null,
      data: [],
    },
    surveyDetailsData: {
      status: null,
      data: {},
    },
    dashboardDetailsData: {
      status: null,
      data: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSurvey.pending.toString(), (state, action) => {
        return {
          ...state,
          allSurveyData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(getAllSurvey.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          allSurveyData: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(getAllSurvey.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          allSurveyData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(getSurveyById.pending.toString(), (state, action) => {
        return {
          ...state,
          surveyDetailsData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(getSurveyById.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          surveyDetailsData: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(getSurveyById.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          surveyDetailsData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(getDashboardDetails.pending.toString(), (state, action) => {
        return {
          ...state,
          dashboardDetailsData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        getDashboardDetails.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            dashboardDetailsData: {
              status: status.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(getDashboardDetails.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          dashboardDetailsData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      });
  },
});

export default DashboardSlice.reducer;
