import React, { Component } from "react";
import FormHelperText from "@mui/material/FormHelperText";
export class CustomJar extends Component {
  renderScale = () => {
    const { ans = "", styleDetails = {}, opts, isDisabled } = this.props;
    const retData = [];
    for (let i = 0; i < opts.length; i++) {
      retData.push(
        <div
          className="d-line-block jar-text"
          style={{
            background: ans === opts[i] ? "#434bdf" : "",
            color: ans === opts[i] ? "#ffffff" : styleDetails.optionColor,
          }}
          onClick={(e) =>
            !isDisabled ? this.handleStateChange(opts[i]) : null
          }
        >
          {opts[i]}
        </div>
      );
    }
    return retData;
  };

  handleStateChange = (value) => {
    this.props.handleStateChange(value);
  };

  render() {
    let {
      errorMsg,
      details,
      description,
      styleDetails = {},
      question,
    } = this.props;
    return (
      <div className="d-block choice-question-container">
        <div className="mb-3">
          <h3
            style={{
              color: styleDetails.questionColor,
              fontSize: `${styleDetails.questionFontSize}px`,
              fontFamily: styleDetails.questionFontFamily,
            }}
          >
            {question} {details?.isMandatory ? "*" : ""}
          </h3>
          <span
            style={{
              color: styleDetails.descriptionColor,
              fontSize: `${styleDetails.descriptionFontSize}px`,
              fontFamily: styleDetails.descriptionFontFamily,
            }}
          >
            {description}
          </span>
        </div>

        <div className="d-flex flex-wrap">{this.renderScale()}</div>
        {errorMsg ? (
          <FormHelperText
            className="MuiFormHelperText-root Mui-error"
            style={{
              color: styleDetails.errorColor,
              fontSize: styleDetails.errorFontSize,
              fontFamily: styleDetails.errorFontFamily,
            }}
          >
            {errorMsg}
          </FormHelperText>
        ) : (
          <></>
        )}
      </div>
    );
  }
}
