import { createAsyncThunk } from "@reduxjs/toolkit";
import { postLoginService } from "Services";

export const handleChangePassword = createAsyncThunk(
  "handleChangePassword",
  async (params) => {
    try {
      const url = `respondent/changePassword`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);
