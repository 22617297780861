import React, { Component } from "react";
import { FormControl, OutlinedInput } from "@mui/material";

class InputQuestion extends Component {
  handleAnsChange = (e) => {
    const { value } = e.target;
    this.props.onChangeAnswer(value);
  };
  render() {
    const { question, answer } = this.props;
    return (
      <div className="d-block choice-question-container">
        <div className="mb-3">
          <h3>{question}</h3>
        </div>
        <div className="d-block input-choice-container">
          <FormControl variant="outlined" className="form-group w-100">
            <OutlinedInput
              className="question-text form-control"
              placeholder="Type your answer"
              value={answer}
              onChange={this.handleAnsChange}
            />
          </FormControl>
        </div>
      </div>
    );
  }
}

export default InputQuestion;
