import React, { Component } from "react";

export class CustomImageUpload extends Component {
  render() {
    let { description, styleDetails = {}, image } = this.props;
    return (
      <div className="free-text-container sdrsterdytrtyr">
        <div
          className="d-flex p-3 rounded justify-content-center w-100 align-items-center upload-library  bg-white mb-4"
          style={{ maxHeight: "300px", border: "0" }}
        >
          <img
            src={image}
            alt=""
            style={{ maxWidth: "100%", maxHeight: "260px" }}
          />
        </div>
        <div
          className="d-flex p-3 rounded justify-content-start w-100 align-items-start upload-library  bg-white mb-4"
          style={{
            maxHeight: "300px",
            background: "transparent",
            boxShadow: "none",
            border: "0",
          }}
        >
          <p
            className="mb-0"
            style={{
              color: styleDetails.descriptionColor,
              fontSize: `${`${styleDetails.descriptionFontSize}px`}px`,
              fontFamily: styleDetails.descriptionFontFamily,
              textAlign: "center",
            }}
          >
            {description}
          </p>
        </div>
      </div>
    );
  }
}
