import React, { Component } from "react";
import {
  GOOGLE_ANALYTICS,
  LOCALSTORAGE_ITEMS,
  PAGE_NAME,
  status,
} from "Constants";
import { navigateRouter } from "Utils/navigateRouter";
import Header from "Postlogin/Components/Header";
import { Box, TextField, Button, Typography } from "@mui/material";
import { LocalStorageService, ValidationEngine } from "Utils/HelperFunctions";
import { addBankDetails } from "ReduxStore/BankDetails/BankDetailsThunk";
import { connect } from "react-redux";
import { alert } from "Utils/alert";
import { GA } from "Utils/ga";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import EditIcon from "@mui/icons-material/Edit";
import AvatarImg from "../../assets/img/login.png";

const validationSchema = {
  bankName: [
    {
      message: "Please enter Bank Name",
      type: ValidationEngine.type.MANDATORY,
    },
  ],
  branchName: [
    {
      message: "Please enter Branch Name",
      type: ValidationEngine.type.MANDATORY,
    },
  ],
  IFSCCode: [
    {
      message: "Please enter IFSC Code",
      type: ValidationEngine.type.MANDATORY,
    },
  ],
  accountNumber: [
    {
      message: "Please enter Account Number",
      type: ValidationEngine.type.MANDATORY,
    },
    {
      message: "Please enter a valid account number!",
      type: ValidationEngine.type.REGEX,
      regex: ValidationEngine.NUMBER_ONLY_REGEX,
    },
  ],
  confirmAccountNumber: [
    {
      message: "Confirm Account Number is required",
      type: ValidationEngine.type.MANDATORY,
    },
    {
      message: "Please enter a valid account number!",
      type: ValidationEngine.type.REGEX,
      regex: ValidationEngine.NUMBER_ONLY_REGEX,
    },
  ],
};

class BankDetails extends Component {
  constructor() {
    super();
    this.state = {
      bankName: "",
      branchName: "",
      IFSCCode: "",
      accountNumber: "",
      confirmAccountNumber: "",
      isSubmitted: false,
      customErrors: {},
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.addBankDetailsRes.status !==
        this.props.addBankDetailsRes.status &&
      this.props.addBankDetailsRes.status === status.SUCCESS
    ) {
      const userData =
        JSON.parse(LocalStorageService.getData(LOCALSTORAGE_ITEMS.userData)) ||
        {};
      GA.dispatchGAEvent(
        GOOGLE_ANALYTICS.EVENT_CATEGORIES.RESPONDENT,
        GOOGLE_ANALYTICS.EVENT_ACTIONS.BANK_DETAILS_ADD,
        `id=${userData._id}`
      );
      alert.success("Bank details added successfully!");
      this.props.navigate(`/app/postlogin/${PAGE_NAME.DASHBOARD}`);
    } else if (
      prevProps.addBankDetailsRes.status !==
        this.props.addBankDetailsRes.status &&
      this.props.addBankDetailsRes.status === status.FAILURE
    ) {
      alert.error("Something went wrong!");
    }
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.validateForm();
      }
    );
  };

  validateForm = () => {
    const {
      bankName,
      branchName,
      IFSCCode,
      accountNumber,
      confirmAccountNumber,
    } = this.state;

    const errors = ValidationEngine.validate(validationSchema, {
      bankName,
      branchName,
      IFSCCode,
      accountNumber,
      confirmAccountNumber,
    });

    const customErrors = { ...errors };

    if (
      accountNumber &&
      confirmAccountNumber &&
      accountNumber !== confirmAccountNumber
    ) {
      customErrors.confirmAccountNumber = {
        isValid: false,
        message: "Account Numbers do not match",
      };
    }

    const isValid = Object.values(customErrors).every(
      (error) => error.isValid !== false
    );

    this.setState({ customErrors });
    return { ...customErrors, isValid };
  };

  handleSubmit = (e) => {
    const { bankName, branchName, IFSCCode, confirmAccountNumber } = this.state;
    e.preventDefault();

    this.setState(
      {
        isSubmitted: true,
      },
      () => {
        const errors = this.validateForm();
        if (errors.isValid) {
          const formData = {
            bankName: bankName,
            branchName: branchName,
            IFSCCode: IFSCCode,
            accountNumber: confirmAccountNumber,
          };
          this.props.addBankDetails(formData);
        }
      }
    );
  };

  handlePaste = (e) => {
    e.preventDefault();
  };

  render() {
    const {
      bankName,
      branchName,
      IFSCCode,
      accountNumber,
      confirmAccountNumber,
      isSubmitted,
      customErrors,
    } = this.state;

    return (
      <Box className="main-container">
        <Header
          data={{
            header: "Bank Details",
            page: PAGE_NAME.BANK_DETAILS,
            backUrl: `/app/postlogin/${PAGE_NAME.DASHBOARD}`,
          }}
        />
        <div
          className="bankDatails-container d-flex align-items-center justify-content-center"
          style={{ height: "84vh" }}
        >
          <div className="bankDatails-inner">
            <div className="avatar-img">
              <Box position={"relative"}>
                <Avatar src={AvatarImg} sx={{ width: 96, height: 96 }}></Avatar>
                <IconButton aria-label="delete" className="btn-primary">
                  <EditIcon />
                </IconButton>
              </Box>
            </div>
            <form className="w-100" onSubmit={this.handleSubmit}>
              <div className="mb-3 form-group">
                <label for="bankName" className="form-label">
                  Bank Name
                </label>
                <input
                  id="bankName"
                  className="form-control"
                  name="bankName"
                  value={bankName}
                  onChange={this.handleChange}
                />
                {isSubmitted && !customErrors.bankName?.isValid ? (
                  <span style={{ color: "red" }}>
                    {customErrors.bankName?.message}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-3 form-group">
                <label for="branchName" className="form-label">
                  Branch Name
                </label>
                <input
                  id="branchName"
                  name="branchName"
                  className="form-control"
                  value={branchName}
                  onChange={this.handleChange}
                />
                {isSubmitted && !customErrors.branchName?.isValid ? (
                  <span style={{ color: "red" }}>
                    {customErrors.branchName?.message}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-3 form-group">
                <label for="IFSCCode" className="form-label">
                  IFSC Code
                </label>
                <input
                  id="IFSCCode"
                  name="IFSCCode"
                  className="form-control"
                  value={IFSCCode}
                  onChange={this.handleChange}
                />
                {isSubmitted && !customErrors.IFSCCode?.isValid ? (
                  <span style={{ color: "red" }}>
                    {customErrors.IFSCCode?.message}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-3 form-group">
                <label for="accountNumber" className="form-label">
                  Account Number
                </label>
                <input
                  id="accountNumber"
                  name="accountNumber"
                  className="form-control"
                  value={accountNumber}
                  onChange={this.handleChange}
                />
                {isSubmitted && !customErrors.accountNumber?.isValid ? (
                  <span style={{ color: "red" }}>
                    {customErrors.accountNumber?.message}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-3 form-group">
                <label for="confirmAccountNumber" className="form-label">
                  Confirm Account Number
                </label>
                <input
                  id="confirmAccountNumber"
                  name="confirmAccountNumber"
                  className="form-control"
                  value={confirmAccountNumber}
                  onChange={this.handleChange}
                  onPaste={this.handlePaste}
                />
                {isSubmitted && !customErrors.confirmAccountNumber?.isValid ? (
                  <span style={{ color: "red" }}>
                    {customErrors.confirmAccountNumber?.message}
                  </span>
                ) : (
                  <></>
                )}
              </div>

              <Button
                className="btn btn-primary rounded px-4 w-100 "
                variant="contained"
                type="submit"
              >
                Submit
              </Button>
            </form>
          </div>
        </div>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const { addBankDetailsRes } = state.bankDetails;
  return { addBankDetailsRes };
};

const mapDispatchToProps = {
  addBankDetails,
};

export default navigateRouter(
  connect(mapStateToProps, mapDispatchToProps)(BankDetails)
);
